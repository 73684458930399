/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'time': {
    width: 26,
    height: 26,
    viewBox: '0 0 26 26',
    data: 'c&gt;<g transform="translate(-470 -14)" _stroke="#154F4A" stroke-width="1.5" _fill="none" fill-rule="evenodd"><circle pid="0" cx="483" cy="27" r="12.25"/><path pid="1" stroke-linejoin="bevel" d="M483 19.09v8.517l4.258-4.258"/></g>'
  }
})
